/* src/components/ChatSessionsModal/ChatSessionsModal.css */

  .session-list {
    overflow-y: auto;
  }
  
  .session-item, .friend-item {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
  }
  
  .session-item:hover,
  .friend-item:hover {
    background-color: #f9fafb;
  }

  .friend-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .friend-info{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .session-details-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .session-main{
    display: flex;
    align-items: center;
  }
  
  .session-details {
    display: flex;
    flex-direction: column;
  }

  .session-img, .friend-img{
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }

  .session-img{
    margin-right: 10px;
  }

  .friend-img{
    margin-top: 10px;
  }
  
  .participant-name, .friend-name {
    font-weight: bold;
    color: #1e3d59;
  }
  
  .session-time {
    display: flex;
    flex-direction: column;
    font-size: 10px;
    color: #888;
  }

  .session-time span{
    margin-top: 2px;
    margin-bottom: 2px;
  }
  
  .latest-message {
    font-size: 14px;
    color: #333;
    margin-top: 5px;
  }
  
  .request-buttons{
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tab-buttons{
    padding: 20px;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #ccc;
  }

  .tab-buttons .active{
    background-color: #0090adb1;
    color: #fff;
  }

  .tab-buttons button{
    border: transparent;
    padding: 10px 30px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
  }

  .request-buttons button{
  border: none;
  cursor: pointer;
  margin-bottom: 5px;
  transition: transform 0.2s;
  border-radius: 8px;
  padding: 5px;
}