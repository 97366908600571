/* src/components/ChatWindow/ChatWindow.css */

.chat-window {
    position: fixed;
    bottom: 0;
    right: 20px;
    width: 350px;
    height: 500px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px 8px 0 0;
    display: flex;
    flex-direction: column;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .chat-header {
    background-color: #0d91ad;
    color: #fff;
    padding: 10px;
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .chat-title {
    font-size: 16px;
  }
  
  .close-chat-button {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  }
  
  .message-area {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    background-color: #ffffff;
    font-size:14px;
  }
  
  .message-block {
    display: flex;
    margin-bottom: 10px;
  }
  
  .sent-message {
    justify-content: flex-end;
  }

  .sent-message .message-item{
    align-items: flex-end;
  }
  
  .received-message {
    justify-content: flex-start;
  }
  
  .message-item {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    max-width: 70%;
    word-wrap: break-word;
  }

  .message-sender{
    padding: 8px 10px;
    border-radius: 15px;
    width: fit-content;
  }
  
  .sent-message .message-sender {
    background-color: #d9eff3;
  }
  
  .received-message .message-sender {
    background-color: #eeeeee;
  }
  
  .message-input-area {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc;
    background-color: #f6f6f6;
  }
  
  .message-input-area input {
    flex: 1;
    padding: 8px;
    border-radius: 20px;
    border: 1px solid #ccc;
    margin-right: 10px;
  }
  
  .message-input-area button {
    background-color: #0091AD;
    color: #fff;
    border: none;
    padding: 8px 15px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .message-input-area button:hover {
    background-color: #00859f;
  }
  
  .message-time {
    font-size: 10px;
    color: #888;
    margin-top: 5px;
    margin-left: 5px;
  }

  .message-friend-avatar{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 8px;
  }