.phase-options {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  flex-direction: column;
}

.phase-button {
  padding: 10px 20px;
  border: none;
  background-color: #0090adb1;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.phase-button.selected {
  background-color: #01778eb1;
  color: white;
}

.phase-button:hover {
  background-color: #026376b1;
}

.small-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1100;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .small-modal  .modal-content {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    width: 400px;
    max-height: 90%;
    overflow-y: auto;
  }
  
  .small-modal  .modal-content h2 {
    margin-bottom: 20px;
  }
  
  .small-modal  .modal-content label {
    display: block;
    margin: 20px 0 5px;
  }
  
  .small-modal  .modal-content select {
    border: 1px solid #cccccc;
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 14px;
  }
  
  .small-modal .submit-button-container button {
    margin-left: 10px;
    margin-top: 20px;
  }

  .small-modal  .interest-tag {
    display: inline-block;
    background-color: #cbe7ece3;;
    padding: 5px 10px;
    margin: 5px;
    border-radius: 20px;
    font-size: 14px;
  }
  
  .small-modal  .interest-tag button {
    background: none;
    border: none;
    color: #0082ad;
    margin-left: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  