.container {
    width: 950px;
    margin: auto;
    padding: 80px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    display: flex;
    justify-content: space-between;
}

.main-container{
    width: 400px;
}

.intro-container{
    width: 300px;
}

.intro-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signin-img{
    width: 250px;
}
.signin-logo{
    width: 250px;
}

.container h2 {
    text-align: center;
    color: #1e3d59;
}

.main-container h2 {
    margin-bottom: 20px;
}

/* .right-margin{
    margin: 20px 80px 5px 0px;
}

.left-margin{
    margin: 5px 0px 5px 80px;
} */

.container label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    box-sizing: border-box;
}

.normal-btn, .highlight-btn{
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10px;
    transition: background-color 0.3s;
}

.normal-btn{
    background-color: #0091AD;
    color: #fff;
}

.normal-btn:hover {
    background-color: #00778f;
}

.highlight-btn{
    background-color: #d6e7ea;
    color: #0091AD;
}

.highlight-btn:hover{
    background-color: #bbdfe5;
}

.container p{
    margin: 3px;
    font-size: 14px;
}

.intro-container p:last-child{
    margin-top: 20px;
    font-weight: 700;
}

.blue-word{
    color: #0091AD;
}