/* src/styles/global.css */

/* 基本樣式 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #e5eaec;
  margin: 0;
  padding: 0;
  color: #333;
  padding-top: 70px;
}

a {
  text-decoration: none;
  color: inherit;
}

/* .Toastify__toast-container {
  top: 100px !important; 
  right: 20px !important; 
} */

/*
.custom-toast-container {
  position: fixed;
  top: 110px;
  right: 20px;
  transform: translateX(-50%); 
  z-index: 9999;
}
*/

.Toastify__toast {
  color: #282828;
  box-shadow: 0 4px 4px rgba(29, 29, 29, 0.5); /* 調整陰影的強度 */
  background-color: #f2fdff;
}

.Toastify__toast--success {
  background-color: #e1ffe9;
}

.Toastify__toast--error {
  background-color: #fde5e8;
}

.toast-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* 背景半透明，實現背景變暗 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000; /* 保證在最上層 */
}

/* .Toastify__toast--warning {
  background-color: hsl(45, 100%, 83%);
}
*/ 

/* .Toastify__toast--info {
  background-color: #cce5e9; 
}  */

.notify-img{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 10px;
}

/* Header 樣式 */
header {
  background-color: #ffffff;
  height: 70px;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(25, 25, 25, 0.1);

  position: fixed; /* 固定 header */
  top: 0; /* 使 header 靠近螢幕頂端 */
  left: 0;
  width: 100%; /* 確保 header 寬度與螢幕相同 */
  z-index: 1000; /* 讓 header 置於其他內容之上 */
}

.header-left{
  display: flex;
  flex-direction: row;
  align-items: center;
}

header .brand {
  display: flex;
  align-items: center;
}

header .brand img {
  height: 65px;
  margin-right: 10px;
}

header .brand h1 {
  margin: 0;
  font-size: 24px;
}

.header-actions{
  color: #0082ad;
}

.header-actions, .header-nav {
  display: flex;
  align-items: center;
}

.header-actions p, .header-nav{
  margin-right: 20px;
  font-weight: 550;
}

.header-actions button,
.header-nav button{
  background-color: transparent;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  margin-left: 15px;
}

.header-actions button{
  border-radius: 50%;
}

.header-nav button{
  border-radius: 5px;
}

.header-nav p{
  margin-top: 5px;
  margin-bottom: 5px;
  color: #0082ad;
}

.header-actions button img{
  width: 20px;
  height: 20px;
}

.header-actions button:hover,
.header-nav button:hover{
  background-color: #0d91ad26;
}

.user-button{
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 15px;
}

.user-button img{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.header-right{
  display: flex;

}

/* 主要內容區域 */
main {
  width: 50%;
  margin: 40px auto;
}

/* 表單樣式 */
.comment-form,
.modal-content {
  background-color: #fff;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.comment-form{
  display: flex;
  flex-direction: row;
}

.modal-content h2 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 20px;
  color: #1e3d59;
}

.modal-content textarea
 {
  width: 100%;
  height: 150px;
  padding: 15px;
  margin-bottom: 15px;
  resize: vertical;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.comment-form input{
  width: 85%;
  height: 42px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  margin-right: 20px;
  margin-left: 10px;
}

.modal-content input{
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  resize: vertical;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.tag-selection {
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
}

.tag-selection button {
  background-color: #e4e6eb;
  color: #050505;
  border: none;
  border-radius: 20px;
  padding: 8px 12px;
  margin: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;
}

.tag-selection button.selected {
  background-color: #1e3d59;
  color: #fff;
}

.tag-selection button:hover {
  background-color: #cbd5e0;
}

/* 圖片上傳樣式 */
.image-upload {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-upload:hover {
  border-color: #1e3d59;
  background-color: #f9fafb;
}

.image-upload input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.image-upload span {
  font-weight: bold;
  color: #1e3d59;
  margin-bottom: 10px;
  pointer-events: none; /* 防止文字接收點擊事件 */
}

.image-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  justify-content: center;
}

.image-preview img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* 按鈕樣式 */
.submit-button-container {
  display: flex;
  justify-content: flex-end; /* 發布按鈕靠右 */
}

.btn-primary {
  background-color: #0091AD;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.btn-primary:hover {
  background-color: #00778f;
}

/* 貼文樣式 */
.post, .user-profile-info, .simple-user-profile-info {
  background-color: #fff;
  padding: 30px;
  margin-bottom: 25px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
  position: relative;
}

.post{
  cursor: pointer;
}

.post:hover {
  background-color: #f9fafb;
}

.post-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.post-header img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
  margin-bottom: auto;
}


.post-header .author-info {
  font-size: 15px;
  color: #000000;
}

.post-header .author-info h3 {
  margin: 0 0 -8px 0;
  font-size: 18px;
  color: #1e3d59;
}

.post-mini-text{
  margin-bottom: 10px;
}

.post-mini-text p,
.post-mini-text button {
  margin-right: 10px;
  font-size: 12px;
  color: #888;
  display: inline;
}

.post-mini-text button{
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.post-content {
  font-size: 16px;
  color: #333;
  margin-bottom: 15px;
}

.post-tags{
  margin-bottom: 15px;
}

.user-interests{
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.user-interests-main{
  margin-top: 50px;
}

.post-tags span, .user-interests span{
  color: #050505;
  border-radius: 20px;
  padding: 5px 10px;
  margin-right: 5px;
  font-size: 13px;
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 5px;
}

.mutual-info span{
  margin-left: 5px;
}
.mutual-info span:first-of-type {
  margin-left: 10px;
}

.mutual-info{
  background-color: #fff7e3;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 20px 30px;
  margin-top: 20px;
}


.post-tags span{
  background-color: #e4e6eb;
}
.user-interests span{
  /* background-color: #d6e9e8; */
  background-color: #e4e6eb;
}

.post-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
  justify-content: center;
}

.post-images img {
  width: 70%;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.post-actions, .profile-actions, .relationship-actions {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
}

.post-actions button, 
.profile-actions button{
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  transition: transform 0.2s;
}

.post-actions button:hover,
.profile-actions button:hover
 {
  transform: scale(1.2);
}

.post-actions button img,
.profile-actions button img {
  width: 20px;
  height: 20px;
}

/* 評論區域樣式 */
/* 評論區域樣式 */
.comments-section {
  margin-top: 30px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.comments-section h4 {
  margin-bottom: 20px;
  color: #1e3d59;
  font-size: 20px;
  border-bottom: 2px solid #e2e8f0;
  padding-bottom: 5px;
}

.comment {
  display: flex;
  align-items: flex-start;
  background-color: #f9fafb;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.comment:hover {
  background-color: #f1f5f9;
}

.comment .avatar{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
  flex-shrink: 0;
  cursor: pointer;
}

.mutualfriend {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 15px;
  flex-shrink: 0;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.comment-content {
  flex: 1;
}

.comment-content .comment-author {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: #1e3d59;
}

.comment-content .comment-timestamp {
  font-size: 12px;
  color: #888;
  margin-left: 10px;
}

.comment-content p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.comment-actions {
  margin-top: 10px;
  text-align: right;
}

.comment-actions button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  transition: transform 0.3s, opacity 0.3s;
  display: inline-flex;
  align-items: center;
}

.comment-actions button img {
  width: 20px;
  height: 20px;
  transition: transform 0.3s, filter 0.3s;
}

.comment-actions button:hover img {
  transform: scale(1.2);
  filter: brightness(0.8);
}

.navigate-back {
  margin-top: 20px;
  display: inline-block;
  background-color: #e2e8f0;
  color: #1e3d59;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.navigate-back:hover {
  background-color: #cbd5e0;
  color: #1e3d59;
}

/* Modal 樣式 */
.modal {
  display: block;
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  margin: 5% auto;
  padding: 30px;
  border-radius: 8px;
  width: 50%;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.modal-content h2 {
  margin-top: 0;
  color: #1e3d59;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 20px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #888;
  transition: color 0.3s;
}

.modal-close:hover {
  color: #1e3d59;
}

/* user profile */

.user-profile-info p {
  /* font-size: 16px; */
  margin: 5px 0;
}

.user-profile-info p span {
  font-weight: bold;
}

.user-profile-info-img{
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.profile-details {
  display: flex;
  align-items: center;
}

.profile-details p, .phase-info p {
  padding: 5px 10px;
  margin-right: 5px;
  font-size: 13px;
  border-radius: 20px;
}

.phase {
  background-color: #0090adb1;
  color: #fff;
}

.origin-school, .exchange-school {
  background-color: #cbe7ece3;
}

.profile-details-arrow{
  margin-right: 4px;
  font-size: 8px;
}

.relationship-actions button,
.relationship-status{
  font-size: 14px;
  border: none;
  margin-left: 10px;
  transition: transform 0.2s;
  border-radius: 5px;
  padding: 8px 12px;
}

.relationship-actions button{
  cursor: pointer;
}

.relationship-status-friend {
  background-color: #e2f4f6;
}

.relationship-status-sent{
  background-color: #ebebeb;
}

.request-actions {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.accept-button,
.send-request-button {
  background-color: #0091AD;
  color: white;
}

.send-request-button:hover,
.accept-button:hover {
  background-color: #007a92;
}

.reject-button, .phase-info button, .new-post-button {
  background-color: #d6e7ea;
  color: #0091AD;
}

.reject-button:hover,
.phase-info button:hover,
.new-post-button:hover {
  background-color: #bbdfe5;
}

.page-subtitle{
  color: #545454;
  margin-bottom: 40px;
}

.page-title{
  margin-bottom: 0;
}

.page-subtitle-em{
  color: #1e3d59;
  font-weight: 500;
}





.phase-modal-container, .side-div {
  position: fixed;
  top: 110px; /* 確保不會擋住 header */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 900;
  width: 20%;
  background-color: #ffffff;
}

.phase-modal-container{
  left: 2.5%;
}

.side-div{
  right: 2.5%;
  display: flex;
  flex-direction: column;
}

.phase-modal-container img{
  margin-top: 15px;
  width: 45%;
}

.side-div img{
  width: 50%;
  margin: 15px auto 0 auto;
}

.phase-info{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.phase-info button, .new-post-button {
  margin: 20px;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 85%;
  font-size: 14px;
}



.side-modal {
  position: fixed;
  top: 70px; /* 確保不會擋住 header */
  right: 0;
  padding: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  width: 22.5%;
  height: calc(100vh - 70px); /* 確保滿屏顯示，避開 header */
  background-color: #ffffff;
  overflow-y: auto; /* 讓內容滾動 */
  border-left: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
}

.no-sessions-message{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40%;
}

.no-sessions-message p{
  margin: 5px;
  color: #4f4f4f;
}



.search-bar {
  position: relative;
  margin-left: 20px;
  margin-right: 10px;
}

.search-bar input{
  border: none;
  background-color: #e3eef0;
  padding: 10px;
  padding-left: 20px;
  border-radius: 30px;
  width: 300px;
  font-size: 14px;
}

.autocomplete-dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  width: 100%;
  z-index: 1000;
  max-height: 500px;
  overflow-y: auto;
  border-radius: 20px;
}

.autocomplete-item {
  font-size: 14px;
  padding: 20px;
  cursor: pointer;
  border-bottom: 1px rgb(209, 209, 209) solid;
}

.autocomplete-item:hover {
  background-color: #f0f0f0;
}

.highlighted {
  color: #0098b6;
  font-weight: bold;
}
